.generate-button {
    background-color: black;
    color: white;
    display: inline-block;
    text-align: center;
}

.generate-button.loading {
    background-color: #88d5fb;
    color: black;
    cursor: not-allowed;
}
  