:root {
    --theme-light: white;
    --theme-light-alpha: rgba(252, 236, 88, 0.5);
    --theme-dark: black;
    --background-highlight-text: rgb(8, 115, 245);
    --theme-dark-alpha: rgba(201, 220, 30, 0.962);
    --dropdown-theme-light: rgb(235, 243, 247);
    --dropdown-theme-dark: rgb(79, 79, 79);
    --theme-gradient-direction: to top;
    --background-gradient: linear-gradient(var(--theme-light), rgb(100, 100, 100));
}

.back-button {
    margin-top: 20px;
    position: absolute;
    top: 20px;
    left: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--theme-dark);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.back-button:hover {
    background-color: var(--dropdown-theme-dark);
}

.back-button svg {
    fill: var(--theme-light);
    width: 24px;
    height: 24px;
}

.changelog-background {
  background:var(--background-gradient);
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--background-gradient);
  padding: 20px;
  box-sizing: border-box;
}

.changelog-title {
  background: none;
  color:var(--theme-dark);
  font-family: 'Garamond', Serif;
  font-size: 2rem;
  margin: 0;
  padding: 20px;
  text-align: center;
  text-decoration: underline;
  text-shadow: 1px 1px 1px var(--theme-dark-alpha);
}

.changelog-items {
    background: none;
    color:var(--background-highlight-text);
    width: 100%;
    list-style-type: disc;
    list-style-position: inside;
    margin-top: 20px;
    padding: 20px;
    text-shadow: 1px 1px 1px var(--theme-dark-alpha);
}

.changelog-items li {
    margin: 10px 0;
}

