:root {
    --option-slider-val: 0%;
}

.options-container {
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0);
    border-radius: 10px;
    height: 85%;
    left: -.5%;
    overflow: hidden;
    padding: 10px;
    position: fixed;
    top:9%;
    width: 22%;
    z-index: 1000;
}

.options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    position: relative;
    transform: translateY(var(--option-slider-val));
}
  
.option {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    gap: 10px;
    padding-left:3%;
    padding-right:3%;
    padding-top:1%;
    padding-bottom:1%;
}

.download-button {
    background-color: black;
    color: white;
    display: inline-block;
    left:0%;
    padding: 6px;
    text-align: center;
}
  