:root {
    --background-light: white;
    --theme-light: white;
    --theme-light-alpha: rgba(235, 243, 247,0.5);
    --background-dark: darkgray;
    --theme-dark: gray;
    --theme-dark-alpha: rgba(79, 79, 79,0.5);
    --dropdown-theme-light: rgb(235, 243, 247);
    --dropdown-theme-dark: rgb(79, 79, 79);
    --theme-gradient-direction: to top;
}
  
.themed-drag-drop-text{
    color:var(--theme-dark);
}

.themed-dropdown {
    border: 1px solid var(--theme-dark);
    padding: 10px;
    margin: 10px 0;
    background: linear-gradient(135deg, rgba(var(--dropdown-theme-dark), 1) 5%, rgba(var(--dropdown-theme-light), 1) 100%);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    transition: all 0.3s ease;
}

.themed-dropdown-switch {
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    color:var(--theme-dark);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .5);
    transition: all 0.3s ease;
}

.themed-dropdown-switch:hover {
    transform: scale(1.25);
}

.themed-dropdown-title {
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    color:var(--theme-dark);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .15);
    transition: all 0.3s ease;
}

.themed-dropdown-title:hover {
    transform: scale(1.05);
}

.themed-generator-confirmation{
    color:var(--theme-light);
    filter:invert(100%);
}

.themed-generate-container {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    color:var(--theme-light);
    filter:invert(100%);
}

.themed-generate-button {
    color:var(--theme-light);
    display: inline-block;
}

.themed-home {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background:var(--background-light);
}

/*background: linear-gradient(var(--theme-gradient-direction), var(--theme-light), rgb(100, 100, 100));*/
    
.themed-info-text {
    color: var(--theme-dark);
}

.themed-option-text{
    color: var(--theme-dark);
}