.dropdown-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 4px 6px rgba(255, 255, 255, 0.25);
    border-radius: 8px;
}

.dropdown-header:hover {
    transform: scale(.99);
}
  
.dropdown-header:hover {
    background-color: rgba(218, 235, 244, 0.25);    
}

.dropdown-content {
    margin-top: 10px;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 4px 6px rgba(255, 255, 255, 0.25);
    border-radius: 8px;
}
