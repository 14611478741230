.cropper {
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  position: absolute;
  transform-origin: center center;
  z-index: 1;
}

.diffusion-container,
.dragdrop-container {
  border: none;
  border-radius: 10px;
  overflow: hidden;

  padding: 20px;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.15), rgba(255, 255, 255, 0.15));
}

.dragdrop-container .drag-files {
  font-family: Arial, sans-serif;
  font-size: 2rem;
  color: black;
  margin-bottom: 20px;
  cursor: pointer;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  background: linear-gradient(to bottom, rgba(240, 240, 240, 0.15), rgba(0, 0, 0, 0.15));
}

.editor-window {
  position: absolute;
  border: 3px solid rgb(133, 215, 245);
  box-sizing: border-box;
  z-index: 2;
  pointer-events: none;
  width: 100%;
}

.generate-button {
    background-color: black;
    color: white;
    display: inline-block;
    text-align: center;
}

.generate-button.loading {
    background-color: #88d5fb;
    color: black;
    cursor: not-allowed;
}

.generator-container,
.dragdrop-container {
  border: none;
}

.generator-container {
  align-items: center;
  background: linear-gradient(to bottom, rgba(240, 240, 240, 0.15), rgba(0, 0, 0, 0.15));
  border-radius: 10px;
  box-shadow: 0 1px 4px black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.generate-wrapper {
  margin-top: 20px;
}

.generator-editor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
}

.input-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
}

.image-preview {
  border: none;
  height: 100%;
  position: relative;
  width: 100%;
}

.image-wrapper {
  position: relative;
  width: auto;
  height: 50vh;
  overflow: hidden;
}

.preview-image {
  filter: blur(50px);
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: cover;
}
