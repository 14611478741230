.container {
  align-items: center;
  bottom: 50%;
  display: flex;
  height: auto;
  justify-content: space-around;
  max-width: calc(100% - 40px);
  position: fixed;
  top:50%;
  width: 80;
}

.theme-toggle{    
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  z-index: 1000;
}

.title {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.005);
  border-radius: 10px;
  position: fixed;
  top: 0;
  left: 0;
  margin: 20px;
  font-family: Arial, sans-serif;
  font-size: 6rem;
  font-weight: bold;
  color: black;
  letter-spacing: 2px;
  -webkit-text-stroke: 1px rgb(255, 255, 255);
  text-shadow: 0 0 3px rgb(50, 50, 50);
  background: linear-gradient(to top, rgb(255, 255, 255), white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: fixed;
  font-size: 400%;
  top: 0;
  left: 0;
  margin: 10px;
  z-index: 1002;
}

.title::after {
  animation: pulse 2s ease-in-out infinite alternate;
  animation: wave 2s ease-in-out infinite alternate;
  content: '';
  position: absolute;
  left: 0px;
  bottom: -3px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5), transparent);
  filter: drop-shadow(0 0 5px rgb(255, 255, 255)) brightness(100%) blur(0.5px);
}

.info {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 999;
  text-align: right;
}


